.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.colored {
  background: -webkit-linear-gradient(0deg, #38BDED 0.26%, #0CE19B 49.82%, #F95352 99.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.nav-white {
  background-color: rgba(255, 255, 255, .35);
  backdrop-filter: blur(15px);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.nav-transparent {
  background-color: white;
}

.mobile-nav.navbar-nav {
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: auto;
}

.mobile-nav > .nav-item {
  white-space: nowrap;
  padding: 10px 16px 0 0;
}

.nav-link:focus, .nav-link:hover {
  color: #38BDED !important;
  font-weight: bold !important;
}

.navbar-expand {
  flex-wrap: wrap !important;
}

@media screen and (min-width: 992px) {
  .nav-transparent {
    background-color: transparent;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
